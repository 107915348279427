import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {Link} from "react-router-dom";
import {DiveButton} from './UI/Button'

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

function Interests() {
    // const classes = useStyles();
    useStyles();

    return <>
        <Typography variant="h6" component="h1">
            Ready to DIVE in
        </Typography>

        <br/>
        <br/>

        <div>
            <DiveButton component={Link} to={'/viewer'} variant="contained" color="primary">
                Proceed to Viewer
            </DiveButton>
        </div>
    </>;
}

export default Interests
