import React from 'react';
import {Redirect} from "react-router-dom";
import {makeStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import {DiveButton} from './UI/Button'
import {readInterests} from "./data";


function isEmailValid(email) {
    const testEmail = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/i;

    return testEmail.test(email)
}

async function postData(url = '', data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
}

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}))

function Email() {
    const userInterests = readInterests()

    const [email, setEmail] = React.useState('');
    const [validEmail, setValidEmail] = React.useState(false);
    const [redirect, setRedirect] = React.useState(false);
    const classes = useStyles();

    const handleChange = (event) => {
        const email = event.target.value;
        setEmail(email);
        isEmailValid(email) ? setValidEmail(true) : setValidEmail(false);
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        postData('https://www.dive-networks.com/dashboard/api/dive-in-email/', {email: email})
            .then(data => {
                console.log(data); // JSON data parsed by `data.json()` call
                setRedirect(true)
            });
    }

    let output;
    if (redirect) {
        output = <Redirect to="/interests"/>;
    } else {
        output = <>
            {userInterests.size === 0
                ? <>
                    <Typography variant="h5" component="h1">
                        Welcome to DIVE
                    </Typography>

                    <br/>

                    <Typography variant="h6" component="h2">
                        Enter your email address to begin
                    </Typography>

                    <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
                        <FormControl fullWidth style={{marginBottom: '.8rem'}}>
                            <InputLabel htmlFor="component-helper">Your Email Address</InputLabel>
                            <Input
                                id="component-helper"
                                value={email}
                                onChange={handleChange}
                                aria-describedby="component-helper-text"
                            />
                            {/*<FormHelperText id="component-helper-text">We will send you a couple of useful tips on how to make the most of the system.</FormHelperText>*/}
                        </FormControl>

                        <div>
                            <DiveButton onClick={handleSubmit} variant="contained" color="primary"
                                    disabled={!validEmail}>
                                Continue
                            </DiveButton>
                        </div>
                    </form>
                </> : <Redirect to="/viewer"/>
            }
        </>;
    }

    return output;
}

export default Email
