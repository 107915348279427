import {
    Adventure,
    Art,
    Food,
    Music,
    Nature,
    Space,
    Style,
    Travel,
    Wellness
} from './backgrounds'

const interestCategories = [
    {id: "adventure", name: "Adventure", img: Adventure, urls: ["https://campaigns.dive-networks.com/programs/v6/?schedule=adventure"], featured: false},
    {id: "artrevised", name: "Art", img: Art, urls: ["https://campaigns.dive-networks.com/programs/v6/?schedule=art"], featured: false},
    {id: "news-food", name: "Food", img: Food, urls: ["https://campaigns.dive-networks.com/programs/v6/?schedule=food"], featured: false},
    {id: "news-dj", name: "Music", img: Music, urls: ["https://campaigns.dive-networks.com/programs/v6/?schedule=music"], featured: false},
    {id: "nature", name: "Nature", img: Nature, urls: ["https://campaigns.dive-networks.com/programs/v6/?schedule=nature"], featured: false},
    {id: "space", name: "Space", img: Space, urls: ["https://campaigns.dive-networks.com/programs/v4/?schedule=space"], featured: false},
    {id: "fashionista", name: "Style", img: Style, urls: ["https://campaigns.dive-networks.com/programs/v6/?schedule=style"], featured: false},
    {id: "news-travel", name: "Travel", img: Travel, urls: ["https://campaigns.dive-networks.com/programs/v6/?schedule=travel"], featured: false},
    {id: "news-health", name: "Wellness", img: Wellness, urls: ["https://campaigns.dive-networks.com/programs/v6/?schedule=wellness"], featured: false},
]

const saveInterests = (interests) => {
    localStorage.setItem('interests', JSON.stringify(Array.from(interests)))
}

const readInterests = () => {
    return new Set(JSON.parse(localStorage.getItem('interests')))
}

export {interestCategories, saveInterests, readInterests}
