import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { blue } from '@material-ui/core/colors'

export const DiveButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText("#244379"),
        backgroundColor: "#244379",
        '&:hover': {
            backgroundColor: blue[900],
        },
    },
}))(Button)
