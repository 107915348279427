import React from 'react';
import Grid from "@material-ui/core/Grid";
import logo from "./logo.svg";
import Paper from "@material-ui/core/Paper";
// import blue from "@material-ui/core/colors/blue";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

function SetupContainer(props) {
    // const bgColor = blue["A700"];
    const bgColor = "#fff";
    // const textColor = blue[50];

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(3),
            margin: 'auto',
            // maxWidth: 500,
            minWidth: 500,
        }
    }));

    const classes = useStyles();

    return <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        // style={{minHeight: '100vh', color: textColor, backgroundColor: bgColor}}
        style={{minHeight: '100vh', backgroundColor: bgColor}}
    >
        <Grid item>
            <Paper elevation={0} className={classes.paper}>
                <Grid container spacing={10}>
                    <Grid item>
                        <img src={logo} alt="DIVE Networks" style={{width: "16em"}}/>
                        <br/>

                        <Typography variant="h5" component="h1">
                            {props.title}
                        </Typography>

                        <br/>

                        <Typography component="p">
                            {props.subtitle}
                        </Typography>
                    </Grid>
                    <Grid item>
                        {props.children}
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    </Grid>;
}

export default SetupContainer



