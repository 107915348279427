import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import './App.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import SetupContainer from './SetupContainer';
import Email from './Email';
import Interests from './Interests';
import Viewer from './Viewer';
import Finish from './Finish';

function App() {
    return (
        <>
            <CssBaseline/>

            <Router>
                <Switch>
                    <Route exact path="/">
                        <SetupContainer>
                            <Email/>
                        </SetupContainer>
                    </Route>
                    <Route path="/interests">
                        <SetupContainer>
                            <Interests/>
                        </SetupContainer>
                    </Route>
                    <Route path="/finish">
                        <SetupContainer>
                            <Finish/>
                        </SetupContainer>
                    </Route>
                    <Route path="/viewer">
                        <Viewer/>
                    </Route>
                </Switch>
            </Router>
        </>
    );
}

export default App;
