import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Link} from "react-router-dom";
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import {interestCategories, readInterests, saveInterests} from './data';
import {DiveButton} from './UI/Button'
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    root: {
        // '& > *': {
        //     margin: theme.spacing(1),
        // },
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: 500,
        height: 453,
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    titleBar: {
        background:
            'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
            'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    icon: {
        color: 'white',
    },
    inlineIcon: {
        verticalAlign: 'middle',
        display: 'inline-flex'
    }
}));

function Interests() {
    const [interests, setInterests] = useState(readInterests());
    const [unused, setUnused] = useState(0); // This is just to make interests hook Set rerender the component

    const classes = useStyles();

    const selectInterest = (tile) => {
        if (interests.has(tile.id)) {
            setUnused(unused + 1);
            interests.delete(tile.id)
            setInterests(interests);
        } else {
            setUnused(unused + 1);
            setInterests(interests.add(tile.id));
        }

        saveInterests(interests)
    }

    return <div className={classes.root}>
            <Grid container spacing={10}>
                <Grid item>
                    <Typography variant="h5" component="h1">
                        What interests you? <small>(Select all that apply)</small>
                    </Typography>

                    <br/>

                    <GridList cellHeight={150} cols={3} spacing={1} className={classes.gridList}>
                        {interestCategories.map((tile) => (
                            <GridListTile key={tile.img}>
                                <img src={tile.img} alt={tile.name}/>
                                <GridListTileBar
                                    title={tile.name}
                                    titlePosition="top"
                                    actionIcon={
                                        <IconButton
                                            aria-label={`star ${tile.name}`}
                                            className={classes.icon}
                                            onClick={() => selectInterest(tile)}>
                                            {interests.has(tile.id)
                                                ? <StarIcon/>
                                                : <StarBorderIcon/>
                                            }
                                        </IconButton>
                                    }
                                    actionPosition="right"
                                    className={classes.titleBar}
                                />
                            </GridListTile>
                        ))}
                    </GridList>
                </Grid>
                <Grid item style={{alignSelf: 'flex-end'}}>
                    <DiveButton component={Link} to={'/finish'} variant="contained" color="primary" disabled={interests.size === 0}>
                        Continue
                    </DiveButton>
                </Grid>
            </Grid>
        </div>;
}

export default Interests
