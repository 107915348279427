import React, {useCallback, useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
// import { green } from '@material-ui/core/colors';
import {Link} from "react-router-dom";
import {interestCategories, readInterests} from './data';

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    // fabGreen: {
    //     color: theme.palette.common.white,
    //     backgroundColor: green[500],
    //     '&:hover': {
    //         backgroundColor: green[600],
    //     },
    // },
}));

function Viewer() {
    const userInterests = Array.from(readInterests()) // Convert set to array to enable access by index
    const [index, setIndex] = useState(0);
    const currentInterestSlug = userInterests[index];
    const [url, setUrl] = useState(0);

    const onMessageReceivedFromIframe = useCallback(
        event => {
            // console.log("onMessageReceivedFromIframe", event.data);

            if (event.data === "finished") {
                console.info("DIVE Campaigns scheduler finish signal picked");
                setIndex(index === userInterests.length - 1 ? 0 : index + 1)
            }
        },
        [index, userInterests]
    )

    useEffect(() => {
        console.info("User interests", userInterests)

        const interestRecord = interestCategories.find((element) => {
            return element.id === currentInterestSlug
        })

        setUrl(interestRecord.urls[Math.floor(Math.random() * interestRecord.urls.length)])

        window.addEventListener("message", onMessageReceivedFromIframe);
        // Extremely important; without this, event will be EXPONENTIALLY registered on every rerender
        return () =>
            window.removeEventListener("message", onMessageReceivedFromIframe);
    }, [onMessageReceivedFromIframe, userInterests, currentInterestSlug]);

    const classes = useStyles();

    return <>
        <Fab component={Link} to={'/interests'} color="primary" aria-label="edit" className={classes.fab}>
            <EditIcon/>
        </Fab>
        <iframe src={url} style={{
            display: 'block',
            background: '#000',
            border: 'none',
            height: '100vh',
            width: '100vw'
        }} onMouseMove={(e) => console.log("Over Edit")} title="viewer"/>
    </>;
}

export default Viewer
